import Image1 from "../../assets/img/portfolio/project-1.jpg";
import Image2 from "../../assets/img/portfolio/project-2.jpg";
// import Image3 from "../../assets/img/portfolio/project-3.jpg";
// import Image4 from "../../assets/img/portfolio/project-4.jpg";
// import Image5 from "../../assets/img/portfolio/project-5.jpg";
// import Image6 from "../../assets/img/portfolio/project-6.jpg";
// import Image7 from "../../assets/img/portfolio/project-7.jpg";
// import Image8 from "../../assets/img/portfolio/project-8.jpg";
// import Image9 from "../../assets/img/portfolio/project-9.jpg";

const PortfolioData = [
  {
    id: 1,
    type: "client project",
    image: Image1,
    tag: ["client"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Web application (Product-based)",
        client: "Smartflow",
        language: "C# (.NET), HTML, CSS, Javascript & more.",
        preview: "www.smartflowapps.com",
        link: "https://www.smartflowapps.com/",
        description:
          "Smartflow, based in The Netherlands, is one of the client company of PROCIT BV. I worked for Smartflow when I was employed at IT@Himalaya, which is a software outsourcing company for PROCIT. I have worked in the Smartflow project for a longer time. It is a product-based application, that is used by over 50 customers worldwide, mainly Europe. The project is realted to digital workflow management, which is used by the customers to manage their business processes.",
        more: "Smartflow consists of more than 15 modules that include Mobile applications (Android & iOS), Web application (ASP.NET MVC), Web API (ASP.NET Web API), Windows application (WPF), Windows service (WCF), SQL Server, Azure, etc. Among these I mainly worked on the backend development using C# (.NET), ASP.NET MVC, ASP.NET Web API, SQL Server, etc. along with deployment, server management, project and resource management. I have worked in features like Single-Sign-On (SSO), workflow builder, REST API, push notification service, report generator, complex stored procedure, test automation, auto-deployment, etc.",
      },
    ],
  },
  {
    id: 2,
    type: "client project",
    image: Image2,
    tag: ["client"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Web application (Service-based)",
        client: "Intrum (Previously Intrum Justitia)",
        language: "ASP, ASP.NET (MVC), HTML, CSS, Javascript & more.",
        preview: "www.intrum.nl",
        link: "https://www.intrum.nl",
        description:
          "Intrum (NBR) is one of the client company of PROCIT BV (The Netherlands). I worked for Intrum when I was employed at IT@Himalaya, which is a software outsourcing company for PROCIT. I have worked in the SIMIM NBR and SIMIMWEB projects, both related to Intrum, for a longer time. It is a service-based application, thousands of people across Europe. The project we worked on is related to collection for music royalty, which is used by the customers to manage their payment processes for the music that play in parties or events.",
        more: "Simim NBR and Simimweb projects had several modules that are inter-related. Most of these modules were related to different ways to collect data regarding the music played in parties or events. The modules included Windows application (VB.NET), Web application (ASP.NET MVC), Web API (ASP.NET Web API), Windows service (WCF), SQL Server, etc. Among these I mainly worked on the frontend development of 'Simimweb' using HTML, CSS, JavaScript, Jquery and More, along with the backend development using ASP.NET MVC, ASP.NET Web API, SQL Server, SSRS, ETL, etc. along with deployment, and testing. I have worked in features like authentication and security, restful webservice, report generation, stored procedures, talend etc.",
      },
    ],
  },
  // {
  //   id: 3,
  //   type: "slider project",
  //   image: Image3,
  //   tag: [],
  //   delayAnimation: "200",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Themeforest",
  //       language: " HTML, CSS, Javascript",
  //       preview: "www.envato.com",
  //       link: "https://www.envato.com",
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   type: "local project",
  //   image: Image4,
  //   tag: ["logo", "video"],
  //   delayAnimation: "0",
  //   modalDetails: [
  //     {
  //       project: "video",
  //       client: "Videohive",
  //       language: " Adobe After Effects",
  //       preview: "www.videohive.net",
  //       link: "https://www.videohive.net",
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   type: "saas project",
  //   image: Image5,
  //   tag: ["logo"],
  //   delayAnimation: "100",
  //   modalDetails: [
  //     {
  //       project: "Web Application",
  //       client: "Themeforest",
  //       language: "HTML, CSS, ReactJS",
  //       preview: "www.envato.com",
  //       link: "https://themeforest.net/item/deski-saas-software-react-template/33799794",
  //     },
  //   ],
  // },
  // {
  //   id: 6,
  //   type: "mockup project",
  //   image: Image6,
  //   tag: ["logo", "mockup"],
  //   delayAnimation: "200",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Themeforest",
  //       language: "HTML, CSS, Javascript",
  //       preview: "www.pexels.com",
  //       link: "https://www.pexels.com",
  //     },
  //   ],
  // },
  // {
  //   id: 7,
  //   type: "facebook project",
  //   image: Image7,
  //   tag: ["logo"],
  //   delayAnimation: "0",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Facebook",
  //       language: "HTML, CSS, Javascript",
  //       preview: "www.facebook.com",
  //       link: "https://www.facebook.com/ibthemes",
  //     },
  //   ],
  // },
  // {
  //   id: 8,
  //   type: "dribble project",
  //   image: Image8,
  //   tag: ["graphic design"],
  //   delayAnimation: "100",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Dribbble",
  //       language: "HTML, CSS, Javascript",
  //       preview: "www.dribbble.com",
  //       link: "https://dribbble.com/ib-themes",
  //     },
  //   ],
  // },
  // {
  //   id: 9,
  //   type: "behence project",
  //   image: Image9,
  //   tag: ["graphic design", "mockup"],
  //   delayAnimation: "200",
  //   modalDetails: [
  //     {
  //       project: "Website",
  //       client: "Behance",
  //       language: "HTML, CSS, Javascript",
  //       preview: "www.behance.com",
  //       link: "https://www.behance.net/ib-themes",
  //     },
  //   ],
  // },
];

export default PortfolioData;
