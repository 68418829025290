import React from "react";

const experienceContent = [
  {
    year: "May 2021 - July 2022",
    position: "Sr. Software Engineer",
    compnayName: "AMNIL Technologies Pvt. Ltd.",
    details: `Provided technical leadership and guidance for software projects for telecommunication company`,
  },
  {
    year: "Apr 2015 - May 2021",
    position: "Lead Software Engineer/Release Manager",
    compnayName: "IT@Himalaya Pvt. Ltd.",
    details: `Streamlined development and delivery of Web application.`,
  },
  {
    year: "Feb 2010 - Nov 2012",
    position: "Senior Software Engineer",
    compnayName: "IT@Himalaya Pvt. Ltd.",
    details: `Led development of web-based application to upgrade, fix bugs, and provide technical support.`,
  },
  {
    year: "Feb 2008 - Jan 2010",
    position: "Junior Software Engineer",
    compnayName: "IT@Himalaya Pvt. Ltd.",
    details: `Identified and fixed bugs during modular changes and upgrades. Streamlined web application development using latest technologies and programming languages.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
