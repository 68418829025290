import React, { useState, useRef } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCaptchaV2 from "react-google-recaptcha";

const Contact = () => {
  const form = useRef();

  const REACT_APP_SITE_KEY = "6Le6JNYoAAAAAN9PSTAWVxcmelYuuqF1oJpsaCh1";

  const [formState, setForm] = useState({
    token: null,
  });

  const handleToken = (token) => {
    setForm((currentForm) => {
      return { ...currentForm, token };
    });
  };

  const handleExpire = () => {
    setForm((currentForm) => {
      return { ...currentForm, token: null };
    });
  };

  const saveMessageToDb = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const uniqueID = uuidv4();
    const token = formState.token;

    formData.append("id", uniqueID);
    formData.append("token", token);

    axios
      .post(
        "https://7rhycd1d90.execute-api.us-east-1.amazonaws.com/v1/message",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        toast.success("Message Sent Successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        form.current.reset();
      })
      .catch((error) => {
        toast.error("Oops, Message Not Sent!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <form
        id="myForm"
        className="contactform"
        ref={form}
        onSubmit={saveMessageToDb}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="firstname"
                placeholder="FIRST NAME"
                required
              />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="lastname"
                placeholder="LAST NAME"
                required
              />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-6">
            <div className="form-group">
              <input type="email" name="email" placeholder="EMAIL" required />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                type="text"
                name="contact"
                placeholder="CONTACT NO. (*Optional)"
              />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-12">
            <div className="form-group">
              <input
                type="text"
                name="subject"
                placeholder="SUBJECT"
                required
              />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                name="message"
                placeholder="MESSAGE"
                required
              ></textarea>
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="form-group">
              <ReCaptchaV2
                sitekey={REACT_APP_SITE_KEY}
                onChange={handleToken}
                onExpire={handleExpire}
              />
            </div>
          </div>

          <div className="col-12">
            <button type="submit" className="button">
              <span className="button-text">Send Message</span>
              <span className="button-icon fa fa-send"></span>
            </button>
          </div>
          {/* End .col */}
        </div>
      </form>
    </>
  );
};

export default Contact;
