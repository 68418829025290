import React from "react";

const personalInfoContent = [
  { meta: "first name", metaInfo: "Diptesh" },
  { meta: "last name", metaInfo: "Shrestha" },
  { meta: "birthday", metaInfo: "Nov 24th" },
  { meta: "Nationality", metaInfo: "Nepalese" },
  { meta: "Open to Work", metaInfo: "YES" },
  { meta: "Address", metaInfo: "Fairfield, Iowa, USA" },
  { meta: "phone", metaInfo: "+1-641-233-9195" },
  { meta: "Email", metaInfo: "diptesh.shresta@gmail.com" },
  { meta: "langages", metaInfo: "Nepali, English, Hindi" },
];

const PersonalInfo = () => {
  return (
    <>
      <ul className="about-list list-unstyled open-sans-font">
        {personalInfoContent.map((val, i) => (
          <li key={i}>
            <span className="title">{val.meta}: </span>
            <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
              {val.metaInfo}
            </span>
          </li>
        ))}
      </ul>
      <ul className="list-unstyled open-sans-font">
        <li>
          <strong>More about Me: </strong>
          <span className="title">
            I am an seasoned Software Engineering with a total of 12 years of
            experience including 7+ years in C# and in the IT industry.
            Throughout my career, I have successfully worked on various related
            to different stages of software development like development,
            deployment, testing, project leadership & so forth. My primary
            expertise lies in C# .NET applications, including HTML, CSS,
            JavaScript, and Relational DBMS like MSSQL and MySQL. My skill set
            extends to utilizing tools like Git, Docker, Jenkins, and PowerShell
            for efficient CI/CD processes, unit testing (like nUnit), JavaScript
            library such as React. In addition to my technical skills, I possess
            a solid understanding of Agile methodologies.
          </span>
        </li>
      </ul>
    </>
  );
};

export default PersonalInfo;
