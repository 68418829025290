import React from "react";

const skillsContent = [
  { skillClass: "p83", skillPercent: "83", skillName: "C#" },
  { skillClass: "p75", skillPercent: "75", skillName: ".NET Framework" },
  { skillClass: "p80", skillPercent: "80", skillName: ".NET Core" },
  { skillClass: "p92", skillPercent: "92", skillName: "HTML" },
  { skillClass: "p66", skillPercent: "66", skillName: "CSS" },
  { skillClass: "p69", skillPercent: "69", skillName: "JavaScript" },
  { skillClass: "p78", skillPercent: "78", skillName: "MS-SQL" },
  { skillClass: "p63", skillPercent: "63", skillName: "NoSQL" },
  { skillClass: "p40", skillPercent: "40", skillName: "REACT" },
  { skillClass: "p56", skillPercent: "56", skillName: "CI/CD" },
  { skillClass: "p77", skillPercent: "77", skillName: "Git" },
  { skillClass: "p68", skillPercent: "68", skillName: "Docker" },
  { skillClass: "p43", skillPercent: "43", skillName: "Azure" },
  { skillClass: "p51", skillPercent: "51", skillName: "AWS" },
];

const Skills = () => {
  return (
    <>
      {skillsContent.map((val, i) => (
        <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
          <div className={`c100 ${val.skillClass}`}>
            <span>{val.skillPercent}%</span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
            {val.skillName}
          </h6>
        </div>
      ))}
    </>
  );
};

export default Skills;
