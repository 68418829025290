import React from "react";

const educationContent = [
  {
    year: "2022 - PRESENT",
    degree: "MASTER DEGREE",
    institute: "MAHARISHI INTERNATIONAL UNIVERSITY",
    details: `Major Courses: Enterprise Architecture, Software Architecture, Web Application Architecture, Software Engineering, Algorithm, Cloud Computing & more.`,
  },
  {
    year: "2013 - 2014",
    degree: "MASTER DEGREE",
    institute: "UNIVERSITY OF WEST LONDON",
    details: `Major Courses: UML Component Modeling, Knowledge Management, Distributed Application Development, Advance Topics in Software Engineering, Research Methods, Dissertation (Topic: Cross-platform mobile application development).`,
  },
  {
    year: "2003 - 2007",
    degree: "BACHELOR DEGREE ",
    institute: "PRIME COLLEGE (TRIBHUVAN UNIVERSITY Affiliated)",
    details: `Major Courses: Computer Information System, Structured Programming, Data Structure and Algorithm, Object Oriented Programming, Database Management System, Web Technology, Java Programming, Artificial Intelligence, Client Server Computing, Software Engineering, Management Information System, Computer Security and Cyber Law, & more.`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
