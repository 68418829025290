import { useState } from "react";
import img1 from "../assets/img/blog/blog-post-1.jpg";
// import img2 from "../assets/img/blog/blog-post-2.jpg";
// import img3 from "../assets/img/blog/blog-post-3.jpg";
// import img4 from "../assets/img/blog/blog-post-4.jpg";
// import img5 from "../assets/img/blog/blog-post-5.jpg";
// import img6 from "../assets/img/blog/blog-post-6.jpg";

const AllBlogData = () => {
  const blogsData = [
    {
      id: 1,
      img: img1,
      title: "Making Application Deployment Effortless with AWS CI/CD",
      commentor: "Diptesh ",
      date: "27 Oct 2027",
      tag: `aws, ci/cd, cloud-computing, automation`,
      description1:
        "In today's fast-paced world of software development, delivering updates and new features to your applications needs to be a seamless and efficient process. Manual deployments, while functional, can become cumbersome, especially when frequent updates are required. But fear not! There's a solution that can revolutionize your deployment process: AWS CI/CD.",
      description2:
        "The key to efficient software deployment is automation. AWS CI/CD not only automates your deployment process but also accelerates it, allowing you to keep pace with the demands of modern software development.",
      description3:
        "Imagine a scenario where making changes to your software application becomes a breeze. With manual deployments, the process often involves a series of steps that can be time-consuming and prone to errors. First, there's the installation of dependencies, which can sometimes lead to version conflicts and wasted time. Next, building your application for production can be resource-intensive and occasionally error-prone when done manually. Then comes the laborious process of managing AWS resources through the AWS Management Console. Navigating through regions and selecting the S3 service can be a tedious task, especially if you're doing it repeatedly. Additionally, managing your S3 buckets entails manually deleting old files and uploading new ones, which can sometimes result in accidents like overwrites or accidental deletions. If you're using CloudFront for content delivery, you're faced with the time-consuming process of manually creating invalidations and waiting for them to propagate, which can be frustrating when you're in a hurry.",
      description4:
        "Here's where AWS CI/CD comes to the rescue. With the AWS cloud at your disposal, you can simplify the entire deployment process. The first step involves creating two S3 buckets, one for your CI/CD pipeline and another for hosting your application. This step is straightforward and can be completed using the user-friendly AWS Management Console. The AWS advantage here is that you don't need to manage the complexities of storage infrastructure and server configurations; AWS takes care of it all for you. Next, we configure CodePipeline, a service that orchestrates the CI/CD workflow. It's here that AWS really shines, as it automates the entire process, eliminating the need for managing various CI/CD tools, web server configurations, or intricate setups. Everything is streamlined, making it accessible to developers of all experience levels.",
      description5:
        "Once your pipeline is set up, CodeBuild comes into play, building your software application seamlessly. The beauty of AWS is its tight integration, meaning you don't have to worry about configuring and managing multiple tools separately. CodeBuild smoothly interacts with other AWS services, simplifying the setup process. Security is vital, and AWS ensures that by allowing you to create IAM roles with appropriate policies. This step eliminates the complexity of access policy management and security setup, enabling secure access within the AWS environment.",
      description6:
        "Finally, with your CI/CD process in place, automation takes the lead in ensuring rapid and consistent deployments. AWS services like CodePipeline and CodeBuild work together harmoniously, leaving no room for manual intervention. This means deployments are not only fast but also reliable, with consistent results each time you push code changes.",
      description7:
        "The advantages of implementing CI/CD with AWS are multifold. Efficiency is paramount, with changes being deployed quickly and minimal downtime. Consistency is a key benefit, as the entire CI/CD workflow is standardized, reducing the chance of human error. Speed is another crucial advantage, as changes are reflected in your live application almost immediately. This agility enables rapid responses to user feedback or changing requirements. Moreover, time and effort savings are substantial. The automation eliminates manual steps, saving time and reducing the potential for errors.",
      description8:
        "The AWS advantage here is significant. It allows you to set up a full-fledged CI/CD pipeline within an hour or even less, sparing you the hassles of managing CI/CD tools, web server configurations, and complex setups for linking with source version control repositories like GitHub. AWS seamlessly integrates these services, ensuring that your CI/CD process is not only efficient but also robust and secure.",
      description9:
        "In conclusion, AWS's CI/CD services are a game-changer in the world of software application deployments. The streamlined process simplifies your workflow, allowing you to focus on what you do best—developing your application. With AWS, your deployment process becomes a breeze, and your updates go live with unmatched efficiency. Say goodbye to the manual hassles, and say hello to effortless deployments. Happy coding!",
    },
    // {
    //   id: 2,
    //   img: img2,
    //   title: "Top 10 Toolkits for Deep Learning in 2022",
    //   commentor: "Santhan ",
    //   date: "14 January 2022",
    //   tag: `wordpress, business, economy, design`,
    //   description1:
    //     "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //   description2:
    //     "If you’ve been waiting for an invitation, this calligraphy is it. Commissioned by Notebook hand-lettered design for a poster. Quote is Notebook Building 8 VP’s Regina Dugan—and mine. ",
    //   description3:
    //     "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    //   description4:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    // },
    // {
    //   id: 3,
    //   img: img3,
    //   title: "Everything You Need to Know About Web Accessibility",
    //   commentor: "steve ",
    //   date: "9 January 2020",
    //   tag: `wordpress, business, economy, design`,
    //   description1:
    //     "Dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //   description2:
    //     "Most photographers find it hard to see interesting pictures in places in which they are most familiar. A trip somewhere new seems always exactly what our photography needed, as shooting away from home consistently inspires us to new artistic heights. ",
    //   description3:
    //     "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    //   description4:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    // },
    // {
    //   id: 4,
    //   img: img4,
    //   title: "How to Inject Humor & Comedy Into Your Brand",
    //   commentor: "Beker ",
    //   date: "15 March 2022",
    //   tag: `wordpress, business, economy, design`,
    //   description1:
    //     "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Suspendisse interdum consectetur libero id faucibu nisl. Lacus vel facilisis volutpat est velit egestas",
    //   description2:
    //     "Most photographers find it hard to see interesting pictures in places in which they are most familiar. A trip somewhere new seems always exactly what our photography needed, as shooting away from home consistently inspires us to new artistic heights. ",
    //   description3:
    //     "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    //   description4:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    // },
    // {
    //   id: 5,
    //   img: img5,
    //   title: "Women in Web Design: How To Achieve Success",
    //   commentor: "Janntul ",
    //   date: "9 January 2021",
    //   tag: `wordpress, business, economy, design`,
    //   description1:
    //     "Jorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //   description2:
    //     "Most photographers find it hard to see interesting pictures in places in which they are most familiar. A trip somewhere new seems always exactly what our photography needed, as shooting away from home consistently inspires us to new artistic heights. ",
    //   description3:
    //     "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    //   description4:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    // },
    // {
    //   id: 6,
    //   img: img6,
    //   title: "Evergreen versus topical content: An overview",
    //   commentor: "Hasan ",
    //   date: "9 January 2022",
    //   tag: `wordpress, business, economy, design`,
    //   description1:
    //     "Still ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //   description2:
    //     "Most photographers find it hard to see interesting pictures in places in which they are most familiar. A trip somewhere new seems always exactly what our photography needed, as shooting away from home consistently inspires us to new artistic heights. ",
    //   description3:
    //     "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    //   description4:
    //     "Still ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    // },
  ];

  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  return {
    singleData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
  };
};

export default AllBlogData;
